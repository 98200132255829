import React from 'react';
import {graphql} from 'gatsby';
import Category from '../templates/category';

import {getFeaturedArticles} from '../utils';

const Page = ({data}) => {
  const excerpt = `Retrouvez ici tous nos articles de blog. Je partage ici tous mes coups de coeur. 
    Looks, déco, voyage, réflexion, avis … J’écris au grès de mes envies et de mes inspirations.`;

  const articles = getFeaturedArticles(data, 'blog');
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <>
      <Category
        title='Blog'
        slug='blog'
        excerpt={excerpt}
        img=''
        siteUrl={siteUrl}
        articles={articles}
      />
    </>
  );
};

export default Page;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStoryblokEntry(filter: {parent_id: {eq: 2560611}}) {
      edges {
        node {
          name
          first_published_at
          slug
          content
          parent_id
        }
      }
    }
  }
`;
